// imports
import React from 'react';
import { useState, createContext } from 'react';

// create context for books
export const CompanyRequestContext = createContext({
  userAccounts: {},
  setUserAccounts: () => {},
  companies: {},
  setCompanies: () => {},
  selectedUser: {},
  setSelectedUser: () => {},
  jobClassifications: {},
  setJobClassifications: () => {},
  occupations: {},
  setOccupations: () => {},
  workHistoryObj: {
    startDate: '',
    endDate: '',
    jobDuties: '',
    additionalNotes: '',
  },
  setWorkHistoryObj: () => {},
});

// create provider for CompanyRequestContext
export const CompanyRequestProvider = props => {
  const [userAccounts, setUserAccounts] = useState({});
  const [companies, setCompanies] = useState({});
  const [selectedUser, setSelectedUser] = useState({});
  const [jobClassifications, setJobClassifications] = useState({});
  const [occupations, setOccupations] = useState([]);
  const [workHistoryObj, setWorkHistoryObj] = useState({});
  return (
    // wraps whole component tree
    <CompanyRequestContext.Provider
      value={{
        userAccounts: userAccounts,
        setUserAccounts: setUserAccounts,
        companies: companies,
        setCompanies: setCompanies,
        selectedUser: selectedUser,
        setSelectedUser: setSelectedUser,
        jobClassifications: jobClassifications,
        setJobClassifications: setJobClassifications,
        occupations: occupations,
        setOccupations: setOccupations,
        workHistoryObj: workHistoryObj,
        setWorkHistoryObj: setWorkHistoryObj,
      }}
    >
      {props.children}
    </CompanyRequestContext.Provider>
  );
};
