import { Chip, makeStyles, TextField } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import React, { useCallback, useState } from 'react';

const useStyles = makeStyles(theme => ({
  chip: {
    backgroundColor: theme.palette.primary.light,
  },
}));

const FluentAutoComplete = props => {
  const classes = useStyles();
  const {
    id,
    label,
    placeholder,
    options,
    textFieldProps,
    onChange: onChangeProp,
    value,
    optionLabel,
    groupBy,
    disabled,
    freeSolo,
    multiple,
    setList,
    noOptionsText,
    ...other
  } = props;
  const [autoCompleteValue, setAutoCompleteValue] = useState(value);

  // Fix onChange to something Field can understand
  const onChange = useCallback(
    (e, value) => (onChangeProp?.(value) ? onChangeProp?.(value) : value),
    [onChangeProp]
  );

  const parseMultiple = useCallback(value => value, []);
  const formatMultiple = useCallback((...args) => args[0] || [], []);

  const AutoCompleteComponent = () => {
    if (freeSolo && !multiple) {
      return (
        <Autocomplete
          id={id}
          freeSolo
          autoSelect
          disabled={disabled}
          options={options || []}
          value={value}
          getOptionLabel={
            optionLabel
              ? option => (option[optionLabel] ? option[optionLabel] : '')
              : option => option
          }
          noOptionsText={noOptionsText}
          onChange={onChange}
          renderInput={params => (
            <TextField
              {...textFieldProps}
              {...params}
              {...other}
              label={label}
              margin="normal"
              variant="outlined"
              fullWidth
              size="small"
            />
          )}
        />
      );
    } else if (!freeSolo && multiple) {
      return (
        <Autocomplete
          multiple
          id={id}
          disabled={disabled}
          options={options || []}
          noOptionsText={noOptionsText}
          getOptionLabel={
            optionLabel
              ? option => (option[optionLabel] ? option[optionLabel] : '')
              : option => option
          }
          onChange={onChange}
          value={value}
          groupBy={groupBy ? option => option[groupBy] : ''}
          parse={parseMultiple}
          format={formatMultiple}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                classes={{ root: classes.chip }}
                label={
                  <span style={{ color: 'white' }}>
                    {optionLabel
                      ? option[optionLabel]
                        ? option[optionLabel]
                        : ''
                      : option}
                  </span>
                }
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={params => (
            <TextField
              {...textFieldProps}
              {...params}
              {...other}
              label={label}
              variant="outlined"
              placeholder={placeholder}
              fullWidth
              size="small"
            />
          )}
        />
      );
    } else if (freeSolo && multiple) {
      return (
        <Autocomplete
          multiple
          id={id}
          disabled={disabled}
          options={options}
          onChange={(e, newval, reason) => {
            setAutoCompleteValue(newval);
            setList(newval);
          }}
          noOptionsText={noOptionsText}
          value={autoCompleteValue}
          parse={parseMultiple}
          format={formatMultiple}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                classes={{ root: classes.chip }}
                label={<span style={{ color: 'white' }}>{option}</span>}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={params => (
            <TextField
              {...textFieldProps}
              {...params}
              {...other}
              label={label}
              variant="outlined"
              placeholder={placeholder}
              fullWidth
              size="small"
              onKeyDown={e => {
                if (e.key === 'Enter' && e.target.value) {
                  if (!autoCompleteValue.includes(e.target.value)) {
                    const updatedValue = autoCompleteValue.concat(
                      e.target.value
                    );
                    setAutoCompleteValue(updatedValue);
                    setList(updatedValue);
                  }
                }
              }}
            />
          )}
        />
      );
    }

    return (
      <Autocomplete
        id={id}
        size="small"
        options={options || []}
        getOptionLabel={
          optionLabel
            ? option => (option[optionLabel] ? option[optionLabel] : '')
            : option => option
        }
        onChange={onChange}
        value={value}
        noOptionsText={noOptionsText}
        groupBy={groupBy ? option => option[groupBy] : ''}
        disabled={disabled}
        renderInput={params => (
          <TextField
            {...textFieldProps}
            {...params}
            {...other}
            placeholder={placeholder ? placeholder : ''}
            variant="outlined"
            label={label}
            fullWidth
            size="small"
            margin="normal"
          />
        )}
      />
    );
  };

  return <AutoCompleteComponent />;
};

export default FluentAutoComplete;
