import React, { Component } from 'react';
import { Alert } from '@material-ui/lab';
import FluentSystemCard from './UI/cards/FluentSystemCard';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
    };
  }

  componentDidCatch(error, info) {
    console.log(error);
    console.log(info);
  }

  render() {
    const { hasError } = this.state;
    const { children, message } = this.props;
    const reload = () => {
      window.location.reload();
    };
    if (hasError) {
      return (
        <>
          <Alert severity="error" onClose={reload}>
            {message}
          </Alert>
          <div style={{ margin: 'auto', marginTop: 50 }}>
            <FluentSystemCard
              header="We are sorry this error occured!"
              subheader="Try refreshing the page"
              // buttonlabel="Create a Support Ticket"
              body={
                <div style={{ marginTop: 20 }}>
                  If this error persists please create a Support Ticket so we
                  can resolve the issue.
                </div>
              }
            />
          </div>
        </>
      );
    }
    return children;
  }
}

export default ErrorBoundary;
