import useAxios, { configure } from 'axios-hooks';
import LRU from 'lru-cache';
import Axios from 'axios';
import { useUser, useLogoutAction } from './unityauth';
import Cookies from 'js-cookie';
import { useUserActions } from './unityauth/users';
import { useHistory } from 'react-router-dom';

const axios = Axios.create({
  baseURL: process.env.REACT_APP_EN_API_BASE,
});

const cache = new LRU({ max: 10 });

configure({ axios, cache });

export const useAxiosQuery = (config, manual, useCache) => {
  let user = useUser();

  let actor = Cookies.get('actor');

  const { setUser } = useUserActions();
  const history = useHistory();
  const logout = useLogoutAction();

  const requestInterceptor = async request => {
    if (user?.accessToken) {
      request.headers['Authorization'] = `Bearer ${user.accessToken}`;
    }

    if (actor) {
      request.headers['X-Actor'] = actor;
    }

    //TODO consider starting an intermittent loader here, end at the end of each other interceptor
    return request;
  };

  const requestErrorInterceptor = async error => {
    throw error;
  };

  const responseInterceptor = async response => {
    return response;
  };

  const responseErrorInterceptor = async error => {
    if (error.response && error.response.status === 401) {
      if (process.env.REACT_APP_EN_UNITY_SSO === 'false') {
        Cookies.remove('token');
        Cookies.remove('actor');
        setUser({ accessToken: null, enId: null });
        history.push('/login');
      }
    }
    if (error.response?.status === 422) {
      let newErrorResponse = '';
      if (error.response?.data?.errors) {
        for (const prop in error.response.data.errors) {
          newErrorResponse = `${newErrorResponse} 
          ${error.response.data.errors[prop]}`;
        }
        error.message = newErrorResponse;
      }
      error.message = error.message;
    }

    throw error;
  };
  axios.interceptors.request.use(requestInterceptor, requestErrorInterceptor);
  axios.interceptors.response.use(
    responseInterceptor,
    responseErrorInterceptor
  );

  const isManual = manual ? manual : config.method === 'get' ? false : true;
  const [{ data, loading, error }, refetch] = useAxios(
    {
      ...config,
      headers: {
        Authorization: `Bearer ${user?.accessToken}`,
        'X-Actor': actor,
      },
    },
    { manual: isManual, useCache: useCache ?? true }
  );

  return [{ data, loading, error }, refetch];
};
