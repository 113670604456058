export const mockMenus = [
  {
    menuInstanceId: '933028ce-692c-4410-9eb4-015beed22c57',
    name: 'Ambient Starter Left Menu',
    verticalId: null,
    menuTypeId: 1,
    productId: 1,
    default: false,
    displayOrder: 1,
    active: true,
    menuItemInstanceStructureViews: [
      {
        menuStructureId: 1,
        menuItemInstance: {
          menuItemInstanceId: 'b21ddb89-e640-4fe7-ace3-701663c972ac',
          clusterId: 1,
          menuItemId: 'da183632-cc69-4027-b211-fe0e14529b97',
          name: 'Home Page',
          parameters:
            '{"FluentIcon":"ViewDashboardIcon","AppIcon":"","Label":"Home Page","Slug":"","RedirectURL":"","i18n":""}',
          productId: null,
          isActive: true,
          roleIds: [],
          items: null,
        },
        title: 'Home Page',
        order: 0,
        parentId: null,
        menuItemInstanceStructures: [],
      },
      {
        menuStructureId: 2,
        menuItemInstance: {
          menuItemInstanceId: '8958692f-a70f-4bf2-9396-86cc0b587f5f',
          clusterId: 2,
          menuItemId: '21f48474-4120-4adc-8d56-135ede8d2d15',
          name: 'Page 1',
          parameters:
            '{"FluentIcon":"FinancialIcon","AppIcon":"","Label":"Page 1","Slug":"","RedirectURL":"","i18n":""}',
          productId: null,
          isActive: true,
          roleIds: [],
          items: null,
        },
        title: 'Page 1',
        order: 1,
        parentId: null,
        menuItemInstanceStructures: [],
      },
      {
        menuStructureId: 3,
        menuItemInstance: {
          menuItemInstanceId: 'f5865d6b-9555-4621-b730-6473faa827d6',
          clusterId: 3,
          menuItemId: '1b269025-6dd6-4a18-b289-9ff2a9425fc5',
          name: 'Page 2',
          parameters:
            '{"FluentIcon":"ReleaseDefinitionIcon","AppIcon":"","Label":"Page 2","Slug":"","RedirectURL":"","i18n":""}',
          productId: null,
          isActive: true,
          roleIds: [],
          items: null,
        },
        title: 'Page 2',
        order: 2,
        parentId: null,
        menuItemInstanceStructures: [],
      },
      {
        menuStructureId: 4,
        menuItemInstance: {
          menuItemInstanceId: '03e294a1-c635-4b8f-a27f-f4fe0e8cbada',
          clusterId: 4,
          menuItemId: 'aeceb1c7-7753-40ee-9ed5-3c44bf418bc1',
          name: 'Page 3',
          parameters:
            '{"FluentIcon":"GlassesIcon","AppIcon":"","Label":"Page 3","Slug":"","RedirectURL":"","i18n":""}',
          productId: null,
          isActive: true,
          roleIds: [],
          items: null,
        },
        title: 'Page 3',
        order: 3,
        parentId: null,
        menuItemInstanceStructures: [],
      },
      {
        menuStructureId: 5,
        menuItemInstance: {
          menuItemInstanceId: 'c2d0e7e2-a82a-4c67-a015-cc76a3bbba2e',
          clusterId: 5,
          menuItemId: 'db18dc4a-2462-40ee-92f8-ccfd16979e67',
          name: 'Nested Pages',
          parameters:
            '{"FluentIcon":"PageIcon","AppIcon":"","Label":"Nested Pages","Slug":"","RedirectURL":"","i18n":""}',
          productId: null,
          isActive: true,
          roleIds: [],
          items: null,
        },
        title: 'Nested Pages',
        order: 4,
        parentId: null,
        menuItemInstanceStructures: [
          {
            menuStructureId: 6,
            menuItemInstance: {
              menuItemInstanceId: '9bdd1a62-0ebe-4517-ad64-b0256ac7be49',
              clusterId: 6,
              menuItemId: 'c4732d72-aca3-46d5-9f8d-457f036e5375',
              name: 'Level 2',
              parameters:
                '{"FluentIcon":"","AppIcon":"","Label":"Level 2","Slug":"","RedirectURL":"","i18n":""}',
              productId: null,
              isActive: true,
              roleIds: [],
              items: null,
            },
            title: 'Level 2',
            order: 0,
            parentId: 5,
            menuItemInstanceStructures: [],
          },
          {
            menuStructureId: 7,
            menuItemInstance: {
              menuItemInstanceId: 'd39d4bef-d4a0-4105-92b2-ffd81e98a9b1',
              clusterId: 7,
              menuItemId: '7f061adf-dbe3-4fac-beac-6eab5516f310',
              name: 'Level 2',
              parameters:
                '{"FluentIcon":"","AppIcon":"","Label":"Level 2","Slug":"","RedirectURL":"","i18n":""}',
              productId: null,
              isActive: true,
              roleIds: [],
              items: null,
            },
            title: 'Level 2',
            order: 1,
            parentId: 5,
            menuItemInstanceStructures: [
              {
                menuStructureId: 8,
                menuItemInstance: {
                  menuItemInstanceId: '67e26501-fe08-4805-ae17-3ed6d0fd37f8',
                  clusterId: 8,
                  menuItemId: '67ba1466-2813-4a5b-8cb4-67a63f7ff35c',
                  name: 'Level 3',
                  parameters:
                    '{"FluentIcon":"","AppIcon":"","Label":"Level 3","Slug":"","RedirectURL":"","i18n":""}',
                  productId: null,
                  isActive: true,
                  roleIds: [],
                  items: null,
                },
                title: 'Level 3',
                order: 2,
                parentId: 7,
                menuItemInstanceStructures: [],
              },
              {
                menuStructureId: 9,
                menuItemInstance: {
                  menuItemInstanceId: '18159cd6-825e-43cb-963a-8e66673d635a',
                  clusterId: 9,
                  menuItemId: 'a4e27a75-6df4-465e-b117-c03ef82f15d8',
                  name: 'Level 3',
                  parameters:
                    '{"FluentIcon":"","AppIcon":"","Label":"Level 3","Slug":"","RedirectURL":"","i18n":""}',
                  productId: null,
                  isActive: true,
                  roleIds: [],
                  items: null,
                },
                title: 'Level 3',
                order: 3,
                parentId: 7,
                menuItemInstanceStructures: [],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    menuInstanceId: '1ff73827-2e68-45f7-8474-0bbd3a9d6bf3',
    name: 'Ambient Starter App Menu',
    verticalId: null,
    menuTypeId: 2,
    productId: 1,
    default: false,
    displayOrder: 1,
    active: true,
    menuItemInstanceStructureViews: [
      {
        menuStructureId: 10,
        menuItemInstance: {
          menuItemInstanceId: 'cbbbcdbf-5c55-465a-b996-c8ae2f1355f1',
          clusterId: 1,
          menuItemId: '6e362d85-7dac-412f-b22e-11123768ed45',
          name: 'Unity App 1',
          parameters:
            '{"FluentIcon":"AddOnlineMeetingIcon","AppIcon":"","Label":"Unity App 1","Slug":"","RedirectURL":"","i18n":""}',
          productId: null,
          isActive: true,
          roleIds: [],
          items: null,
        },
        title: 'Unity App 1',
        order: 0,
        parentId: null,
        menuItemInstanceStructures: [],
      },
      {
        menuStructureId: 11,
        menuItemInstance: {
          menuItemInstanceId: '1f7ce29f-6234-45d2-abdc-a82f5f1db0b9',
          clusterId: 1,
          menuItemId: 'ac1954d3-3b5b-4d23-817a-92b687d564c1',
          name: 'Unity App 2',
          parameters:
            '{"FluentIcon":"ClipboardListIcon","AppIcon":"","Label":"Unity App 2","Slug":"","RedirectURL":"","i18n":""}',
          productId: null,
          isActive: true,
          roleIds: [],
          items: null,
        },
        title: 'Unity App 2',
        order: 0,
        parentId: null,
        menuItemInstanceStructures: [],
      },
      {
        menuStructureId: 12,
        menuItemInstance: {
          menuItemInstanceId: '7b041e39-f21f-463b-bd98-a44ed3d0dc55',
          clusterId: 1,
          menuItemId: 'f359a62a-3225-4eab-8003-ab3ad5ae5049',
          name: 'Unity App 3',
          parameters:
            '{"FluentIcon":"ReceiptCheckIcon","AppIcon":"","Label":"Unity App 3","Slug":"","RedirectURL":"","i18n":""}',
          productId: null,
          isActive: true,
          roleIds: [],
          items: null,
        },
        title: 'Unity App 3',
        order: 0,
        parentId: null,
        menuItemInstanceStructures: [],
      },
      {
        menuStructureId: 13,
        menuItemInstance: {
          menuItemInstanceId: 'b686850c-bbcb-43fa-aeeb-013b13e0a0f3',
          clusterId: 1,
          menuItemId: '2c4873e8-1cb6-439b-93c2-fa80aee09c1e',
          name: 'Unity App 4',
          parameters:
            '{"FluentIcon":"DiagnosticIcon","AppIcon":"","Label":"Unity App 4","Slug":"","RedirectURL":"","i18n":""}',
          productId: null,
          isActive: true,
          roleIds: [],
          items: null,
        },
        title: 'Unity App 4',
        order: 0,
        parentId: null,
        menuItemInstanceStructures: [],
      },
    ],
  },
  {
    menuInstanceId: '225bfcf8-8738-492b-b91f-5da7db6562fe',
    name: 'Ambient Starter Builders Menu',
    verticalId: null,
    menuTypeId: 3,
    productId: 1,
    default: false,
    displayOrder: 1,
    active: true,
    menuItemInstanceStructureViews: [
      {
        menuStructureId: 14,
        menuItemInstance: {
          menuItemInstanceId: '20b0d0f7-98a5-4979-91de-64fa422a4e7b',
          clusterId: 1,
          menuItemId: '1baae5f2-e514-4f0a-a979-7b33fd70091c',
          name: 'Unity Builder 1',
          parameters:
            '{"FluentIcon":"SearchAndAppsIcon","AppIcon":"","Label":"Unity Builder 1","Slug":"","RedirectURL":"","i18n":""}',
          productId: null,
          isActive: true,
          roleIds: [],
          items: null,
        },
        title: 'Unity Builder 1',
        order: 0,
        parentId: null,
        menuItemInstanceStructures: [],
      },
      {
        menuStructureId: 15,
        menuItemInstance: {
          menuItemInstanceId: '6e9d65ee-0605-4eff-a2de-add2d117b277',
          clusterId: 1,
          menuItemId: 'bc08a5ed-3451-42d7-a34d-45b69124ab2f',
          name: 'Unity Builder 2',
          parameters:
            '{"FluentIcon":"MobileReportIcon","AppIcon":"","Label":"Unity Builder 2","Slug":"","RedirectURL":"","i18n":""}',
          productId: null,
          isActive: true,
          roleIds: [],
          items: null,
        },
        title: 'Unity Builder 2',
        order: 0,
        parentId: null,
        menuItemInstanceStructures: [],
      },
      {
        menuStructureId: 16,
        menuItemInstance: {
          menuItemInstanceId: 'd3cff215-3a3f-41b8-9a2c-d7c596704643',
          clusterId: 1,
          menuItemId: '9f9e1d20-c06b-4ecc-a728-c6ce2896be0e',
          name: 'Unity Builder 3',
          parameters:
            '{"FluentIcon":"BullseyeIcon","AppIcon":"","Label":"Unity Builder 3","Slug":"","RedirectURL":"","i18n":""}',
          productId: null,
          isActive: true,
          roleIds: [],
          items: null,
        },
        title: 'Unity Builder 3',
        order: 0,
        parentId: null,
        menuItemInstanceStructures: [],
      },
    ],
  },
  {
    menuInstanceId: '8aab5eda-d9d9-4a65-b27d-0fd5c761da45',
    name: 'Ambient Starter Favorites Menu',
    verticalId: null,
    menuTypeId: 4,
    productId: 1,
    default: false,
    displayOrder: 1,
    active: true,
    menuItemInstanceStructureViews: [
      {
        menuStructureId: 17,
        menuItemInstance: {
          menuItemInstanceId: '4e75ca33-d485-4bd8-82e9-f366f23f34cc',
          clusterId: 1,
          menuItemId: '1859774a-54f7-4d8b-a18a-e5974a0d621e',
          name: 'Favorites 1',
          parameters:
            '{"FluentIcon":"PostUpdateIcon","AppIcon":"","Label":"Favorites 1","Slug":"","RedirectURL":"","i18n":""}',
          productId: null,
          isActive: true,
          roleIds: [],
          items: null,
        },
        title: 'Favorites 1',
        order: 0,
        parentId: null,
        menuItemInstanceStructures: [],
      },
      {
        menuStructureId: 18,
        menuItemInstance: {
          menuItemInstanceId: 'b9683895-5eda-4a4d-af9c-509ba61d1555',
          clusterId: 1,
          menuItemId: 'd98490fd-eb39-46cb-a8a7-fe0a657d67f6',
          name: 'Favorites 2',
          parameters:
            '{"FluentIcon":"GenericScanIcon","AppIcon":"","Label":"Favorites 2","Slug":"","RedirectURL":"","i18n":""}',
          productId: null,
          isActive: true,
          roleIds: [],
          items: null,
        },
        title: 'Favorites 2',
        order: 0,
        parentId: null,
        menuItemInstanceStructures: [],
      },
      {
        menuStructureId: 19,
        menuItemInstance: {
          menuItemInstanceId: '9fc5c248-c0ff-46c2-b7ff-5234b8affa1c',
          clusterId: 1,
          menuItemId: '8ca09db2-697c-4867-adac-a84cf0d3d645',
          name: 'Favorites 3',
          parameters:
            '{"FluentIcon":"TelemarketerIcon","AppIcon":"","Label":"Favorites 3","Slug":"","RedirectURL":"","i18n":""}',
          productId: null,
          isActive: true,
          roleIds: [],
          items: null,
        },
        title: 'Favorites 3',
        order: 0,
        parentId: null,
        menuItemInstanceStructures: [],
      },
    ],
  },
];

export const mockGridOptions = {
  defaultColDef: { resizable: true },
  columnDefs: [
    {
      headerName: 'Column 1',
      field: 'column1',
      resizable: true,
      sortable: true,
    },
    {
      headerName: 'Column 2',
      field: 'column2',
      resizable: true,
      sortable: true,
    },
    {
      headerName: 'Column 3',
      field: 'column3',
      resizable: true,
      sortable: true,
    },
    {
      headerName: 'Column 4',
      field: 'column4',
      resizable: true,
      sortable: true,
    },
    {
      headerName: 'Column 5',
      field: 'column5',
      resizable: true,
      sortable: true,
    },
  ],
};

export const mockRowData = [
  {
    column1: 'Row 1 Column 1',
    column2: 'Row 1 Column 2',
    column3: 'Row 1 Column 3',
    column4: 'Row 1 Column 4',
    column5: 'Row 1 Column 5',
  },
  {
    column1: 'Row 2 Column 1',
    column2: 'Row 2 Column 2',
    column3: 'Row 2 Column 3',
    column4: 'Row 2 Column 4',
    column5: 'Row 2 Column 5',
  },
  {
    column1: 'Row 3 Column 1',
    column2: 'Row 3 Column 2',
    column3: 'Row 3 Column 3',
    column4: 'Row 3 Column 4',
    column5: 'Row 3 Column 5',
  },
  {
    column1: 'Row 4 Column 1',
    column2: 'Row 4 Column 2',
    column3: 'Row 4 Column 3',
    column4: 'Row 4 Column 4',
    column5: 'Row 4 Column 5',
  },
  {
    column1: 'Row 5 Column 1',
    column2: 'Row 5 Column 2',
    column3: 'Row 5 Column 3',
    column4: 'Row 5 Column 4',
    column5: 'Row 5 Column 5',
  },
];
