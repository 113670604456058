import {
  Button,
  CardContent,
  Divider,
  makeStyles,
  Popover,
  TextField,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import clsx from 'clsx';
import Cookies from 'js-cookie';
import React, { forwardRef, useEffect, useState } from 'react';
// import useId from '../../../utils/useId';
import { useHistory } from 'react-router-dom';
import { FluentButton } from 'unity-fluent-library';
import UserAvatar from '../../UI/user/UserAvatar';
import { userHasPermission } from '../../utils/userUtils';
import { useUser } from '../../utils/unityauth';

//const userContext = useUser();
const actorCookie = Cookies.get('actor');

const useStyles = makeStyles(theme => ({
  cardRoot: {
    '& > *': {
      margin: theme.spacing(1),
    },
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  popoverRoot: {
    maxWidth: 350,
    marginTop: theme.spacing(2),
  },
  menuTitle: {
    margin: theme.spacing(1),
  },
  cardHeader: {
    display: 'flex',
    marginBottom: theme.spacing(3),
  },
  currentCompany: {
    margin: 'auto',
    width: '85%',
    marginBottom: theme.spacing(4),
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
  },
  basicInfoContainer: {
    marginLeft: theme.spacing(1),
  },
  basicInfo: {
    paddingLeft: theme.spacing(1),
  },
  divider: {
    height: theme.spacing(3),
  },
  avatar: {
    fontSize: 40,
  },
  button: {
    color: 'black',
    backgroundColor: 'white',
    '& .MuiButton-label': {
      fontSize: 11,
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.lightest,
    },
  },
}));

let FluentUserMenu = (props, ref) => {
  const classes = useStyles();
  const {
    anchorEl,
    disableAutoFocus,
    disableEnforceFocus,
    disableRestoreFocus,
    onClose,
    onEnter,
    onEntered,
    onEntering,
    onExit,
    onExited,
    onExiting,
    open,
    logout,
    name,
    id: idProp,
    className,
    user,
    company,
    setCompany,
    companies,
    setUserSwitchOpen,
    permissions,
    handleLogout,
    close,
    ...other
  } = props;
  const titleId = `company-title`;
  const history = useHistory();

  const [currentCompanyVal, setCurrentCompanyVal] = useState(
    company && companies ? companies.find(c => c.companyId === company.id) : {}
  );

  useEffect(() => {
    if (currentCompanyVal !== undefined) {
      if (Object.keys(currentCompanyVal).length === 0 && company) {
        setCurrentCompanyVal(
          user && companies
            ? companies.find(c => c.companyId === user.currentProfileCompanyId)
            : {}
        );
      }
    }
  }, [companies, company, currentCompanyVal, user]);

  const handleAutocompleteOnChange = (newValue, reason) => {
    if (reason !== 'select-option') {
      return;
    }

    setCurrentCompanyVal(newValue);
    setCompany({
      id: newValue.companyId,
      name: newValue.companyName,
    });
    sessionStorage.setItem(
      'company',
      JSON.stringify({
        id: newValue.companyId,
        name: newValue.companyName,
      })
    );

    history.push('/');
  };

  const handleUserSwitchClick = () => {
    setUserSwitchOpen(true);
  };

  const handleAdminSettings = () => {
    history.push('/admin-settings');
    close(); //closes the popover
  };

  const handleRemoveActor = () => {
    Cookies.remove('actor');
    window.location.reload();
  };

  return (
    <Popover
      ref={ref}
      {...{
        anchorEl,
        disableAutoFocus,
        disableEnforceFocus,
        disableRestoreFocus,
        onClose,
        onEnter,
        onEntered,
        onEntering,
        onExit,
        onExited,
        onExiting,
        open,
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      PaperProps={{
        ...other,
        role: 'navigation',
        'aria-labelledby': titleId,
        className: clsx(className, classes.popoverRoot),
        square: true,
      }}
    >
      <CardContent className={classes.cardRoot}>
        <div className={classes.cardHeader}>
          <UserAvatar
            name={user ? user.name : ''}
            email={user ? user.email : ''}
            size="large"
            className={classes.avatar}
          />
          <div className={classes.basicInfoContainer}>
            <Typography className={classes.basicInfo} variant="subtitle2">
              {user ? user.name : ''}
            </Typography>
            <Typography className={classes.basicInfo} variant="body2">
              {user ? user.email : ''}
            </Typography>
            <div className={classes.buttonGroup}>
              {user &&
              userHasPermission(user.permissions, 'permissions_set') ? (
                <FluentButton
                  className={classes.button}
                  type="button"
                  variant="contained"
                  color="secondary"
                  onClick={handleUserSwitchClick}
                >
                  Fast User Switching
                </FluentButton>
              ) : (
                ''
              )}
              {user && userHasPermission(user.permissions, 'settings_view') ? (
                <>
                  <Divider className={classes.divider} orientation="vertical" />
                  <FluentButton
                    className={classes.button}
                    type="button"
                    variant="contained"
                    color="secondary"
                    onClick={handleAdminSettings}
                  >
                    Admin Settings
                  </FluentButton>
                </>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        {/* {user?.userType === 'admin' ? (
          <Autocomplete
            className={classes.currentCompany}
            value={currentCompanyVal}
            disableClearable
            id="company"
            name="company"
            options={companies || []}
            getOptionLabel={option =>
              option?.companyName ? option?.companyName : ''
            }
            onChange={(event, newValue, reason) =>
              handleAutocompleteOnChange(newValue, reason)
            }
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                margin="normal"
                label="Current Company"
              />
            )}
          />
        ) : (
          ''
        )} */}
        <Button onClick={handleLogout} variant="outlined" color="secondary">
          Sign-out
        </Button>
        {actorCookie !== undefined ? (
          <Button
            onClick={handleRemoveActor}
            variant="outlined"
            color="secondary"
          >
            Exit Fast User Switch
          </Button>
        ) : (
          ''
        )}
      </CardContent>
    </Popover>
  );
};
FluentUserMenu = forwardRef(FluentUserMenu);

export default FluentUserMenu;
