import React, {
  useEffect,
  useCallback,
  cloneElement,
  useMemo,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import MenuOpener from '../utils/MenuOpener';
import UserAvatar from '../utils/UserAvatar';
import FluentAutoComplete from '../UI/inputs/select/FluentAutoComplete';
import FluentUserMenu from '../components/fluentComponents/FluentUserMenu';
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import {
  AppBar,
  Button,
  IconButton,
  makeStyles,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { FluentProfileMenuCard } from 'unity-fluent-library';
import { useAxiosQuery } from '../utils/useAxios';
import { useSingleLogout } from '../utils/unityauth/useSingleLogout';
import useId from '../utils/useId';
import { clearStoredPath } from '../utils/redirect/LocalRedirectUrlStorage';
import { clearStoredExternalPath } from '../utils/redirect/ExternalRedirectUrlStorage';
import Cookies from 'js-cookie';
import { useUserActions } from '../utils/unityauth/users';
import { useUser, useLogoutAction } from '../utils/unityauth';
import { getUserFullName } from '../utils/userUtils';
import { getCompany } from '../utils/companyUtils';
import ModalAlert from '../components/ui/elements/ModalAlert';
import { useSnackbar } from 'notistack';
import { userHasPermission, sortToLatestUserProfile } from '../utils/userUtils';

const useStyles = makeStyles(theme => ({
  root: {},
  avatar: {
    background: theme.palette.secondary.main,
  },
}));

const IS_LOCAL_AUTH = process.env.REACT_APP_LOCAL_AUTH === 'true';

const UserWrapper = props => {
  const { setUser } = useUserActions();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const logout = useLogoutAction();
  const classes = useStyles();
  const history = useHistory();
  const { returnTo, userContext } = props;
  const [userSwitchOpen, setUserSwitchOpen] = useState(false);
  const singleLogout = useSingleLogout();
  const [company, setCompany] = useState(null);
  const [{ data: companiesData }, refetchCompanies] = useAxiosQuery({
    url: `company?organizationType=company`,
    method: 'get',
  });
  const companies = useMemo(() => {
    if (!companiesData) {
      return [];
    }
    return companiesData.company
      .filter(c => c.companyStatus === 'active')
      .sort((a, b) => a.companyName.localeCompare(b.companyName));
  }, [companiesData]);

  const [{ data: loggedUserData }, refetchUser] = useAxiosQuery(
    {
      url: `users/${userContext.actor_enId || userContext?.enId}`,
      method: 'get',
    },
    !userContext
  );
  const user = useMemo(() => {
    if (!loggedUserData) {
      return null;
    }
    const userObj = loggedUserData.user;
    return {
      userId: userObj?.userId,
      userType: userObj.userType,
      userCondition: userObj.userCondition,
      current_profileId: userObj.current_profileId,
      currentProfileCompanyId: userObj.currentProfile.companyId,
      name: getUserFullName(
        userObj.currentProfile.firstName,
        userObj.currentProfile.middleName,
        userObj.currentProfile.lastName
      ),
      email: userObj.emailAddress_primary,
      permissions: userObj.permissions,
    };
  }, [loggedUserData]);

  // GET list of User Profiles
  const [{ data: userProfilesData }, refetchUserProfiles] = useAxiosQuery({
    url: `userProfiles`,
    method: 'get',
  });
  const userProfiles = useMemo(() => {
    if (!userProfilesData) {
      return null;
    }

    return sortToLatestUserProfile(userProfilesData);
  }, [userProfilesData]);

  const [{ data: userAccountsData }, refetchUserAccounts] = useAxiosQuery({
    url: `userAccounts?company=${company?.id}`,
    method: 'get',
  });
  const users = useMemo(() => {
    if (!userAccountsData || !userProfiles) {
      return null;
    }

    let usersArr = [
      ...userAccountsData.users.filter(
        user => user.userStatus === 'active' || user.userStatus === 'pending'
      ),
    ];

    usersArr.forEach(user => {
      const profile = userProfiles.find(
        profile => profile.userId === user.userId
      );

      const userType = user.userType;
      // .replace(/((?<!^)[A-Z](?![A-Z]))(?=\S)/g, ' $1')
      // .replace(/^./, s => s.toUpperCase());
      user.name =
        profile?.firstName + ', ' + profile?.lastName + ' [' + userType + ']';
    });

    return usersArr.sort((a, b) => a.name.localeCompare(b.name));
  }, [userAccountsData, userProfiles]);

  const snackbarConfirmAction = useCallback(
    key => (
      <Button
        style={{ color: 'white' }}
        color="white"
        size="small"
        onClick={() => {
          closeSnackbar(key);
        }}
      >
        OK
      </Button>
    ),
    [closeSnackbar]
  );

  const handleLogout = () => {
    clearStoredPath();
    clearStoredExternalPath();
    sessionStorage.removeItem('company');
    if (process.env.REACT_APP_EN_UNITY_SSO === 'true') {
      setUser({ accessToken: null, enId: null });
      Cookies.remove('actor');
      logout();
    } else {
      Cookies.remove('token');
      Cookies.remove('actor');
      setUser({ accessToken: null, enId: null });
      // history.push('/login');
      window.location.reload();
    }
  };

  const handleUserSwitch = () => {
    if (selectedUserForSwitch === null) {
      enqueueSnackbar(`Please select a user to switch to.`, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        autoHideDuration: 3000,
        action: snackbarConfirmAction,
      });
    } else {
      Cookies.set('actor', selectedUserForSwitch.userId);
      sessionStorage.removeItem('company');
      history.push('/profile/summary');
      window.location.reload();
    }
  };

  const [selectedUserForSwitch, setSelectedUserForSwitch] = useState(null);

  const UserMenuOpener = ({
    name,
    button,
    sheet,
    disabled,
    user,
    company,
    setCompany,
    UserMenu: UserMenuComponent,
    UserMenuProps,
    companies,
    setUserSwitchOpen,
    permissions,
    handleLogout,
  }) => {
    const popupId = useId(name);
    const popupState = usePopupState({ variant: 'popover', popupId });
    const { close } = popupState;
    const click = useCallback(
      e => {
        const isButtonClick = e.target.matches(
          'a, button, input[type="button"], input[type="image"], [role="button"], [role="link"], a *, button *, [role="button"] *, [role="link"] *, li'
        );
        const clickedCompanyName = e.target.outerText;
        if (isButtonClick) {
          const company = companies.find(
            c => c.companyName === clickedCompanyName
          );
          if (company !== null && company !== undefined && company !== '') {
            setCompany({
              id: company.companyId,
              name: company.companyName,
            });
          }
          // close(); //this closes the popover when the autocomplet drop down arrow is clicked
        }
      },
      [close, companies, setCompany]
    );

    return (
      <>
        {cloneElement(button, bindTrigger(popupState))}
        <UserMenuComponent
          {...bindPopover(popupState)}
          {...UserMenuProps}
          onClick={click}
          user={user}
          company={company}
          companies={companies}
          setCompany={setCompany}
          handleLogout={handleLogout}
          setUserSwitchOpen={setUserSwitchOpen}
          permissions={permissions}
          close={close}
        />
      </>
    );
  };

  useEffect(() => {
    if (companiesData && user) {
      setCompany(getCompany(companiesData, user));
    }
  }, [companiesData, user]);

  useEffect(() => {
    if (!user) {
      return;
    }
  }, [user]);

  if (!user) return null;

  return (
    <div className={classes.root}>
      {user ? (
        <>
          <UserMenuOpener
            button={
              <IconButton>
                <UserAvatar
                  name={user ? user.name : ''}
                  email={user ? user.email : ''}
                  size="toolbar"
                />
              </IconButton>
            }
            UserMenu={FluentUserMenu}
            user={user}
            companies={companies}
            company={company}
            setCompany={setCompany}
            setUserSwitchOpen={setUserSwitchOpen}
            permissions={user ? user.permissions : {}}
            handleLogout={handleLogout}
          />
          <ModalAlert
            title={'Fast User Switch'}
            message={
              <>
                <Typography variant="body1">
                  Choose a user to switch to:
                </Typography>
                <FluentAutoComplete
                  id="users"
                  // label="Users"
                  textFieldProps={{
                    id: 'users',
                    name: 'users',
                  }}
                  value={selectedUserForSwitch}
                  options={users || []}
                  fullWidth
                  optionLabel="name"
                  initialValue={''}
                  onChange={event => {
                    setSelectedUserForSwitch(event);
                  }}
                />
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  onClick={() => handleUserSwitch()}
                >
                  Switch User
                </Button>
                {/* <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={() => handleRemoveActor()}
            >
              Exit user
            </Button> */}
              </>
            }
            closeAlert={() => setUserSwitchOpen(false)}
            open={userSwitchOpen}
          />
        </>
      ) : (
        <Button
          component={Link}
          to={{ pathname: '/login', state: { returnTo } }}
          color="inherit"
        >
          Sign in
        </Button>
      )}
    </div>
  );
};
export default UserWrapper;
