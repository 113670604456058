import React, { ReactNode, FC } from 'react';
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  HttpLink,
  ApolloLink,
} from '@apollo/client';
// import { onError } from 'apollo-link-error';
// import { snakeCase } from 'snake-case';
import { useMemo } from 'react';
import apolloLogger from 'apollo-link-logger';

export interface GqlApiProviderProps {
  children: ReactNode;
}

let link: ApolloLink = new HttpLink({
  uri: process.env.REACT_APP_API_BASE,
});

if (
  (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') &&
  process.env.REACT_APP_USE_MOCKS
) {
  link = require('./mockSchema').default;
}

if (process.env.NODE_ENV === 'development') {
  link = ApolloLink.concat(apolloLogger, link);
}

export function makeClient(): ApolloClient<any> {
  return new ApolloClient({
    link,
    cache: new InMemoryCache({
      typePolicies: {
        DashboardWidgetLayouts: {
          keyFields: false,
        },
        DashboardWidgetLayout: {
          keyFields: false,
        },
      },
    }),
  });
}

/**
 * GraphQL API client provider
 */
export const GqlApiProvider: FC<GqlApiProviderProps> = ({ children }) => {
  const client = useMemo(() => makeClient(), []);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
