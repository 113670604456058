import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useUser } from '../unityauth';
import Cookies from 'js-cookie';

const PrivateRoute = ({ component: Component, render, ...otherProps }) => {
  const userContext = useUser();
  if (
    userContext?.accessToken === '' &&
    process.env.REACT_APP_EN_UNITY_SSO === 'true'
  )
    return null;

  const renderContent = props => {
    if (
      !userContext?.accessToken &&
      process.env.REACT_APP_EN_UNITY_SSO === 'false'
    ) {
      if (Cookies.get('token')) {
        return null; //if token in cookies, will try to get user without logging in again
      }
      return (
        <Redirect
          to={otherProps.redirectTo ? otherProps.redirectTo : '/login'}
        />
      );
    }

    return typeof render === 'function' ? (
      render(props)
    ) : (
      <Component {...props} />
    );
  };

  return <Route {...otherProps} render={renderContent} />;
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export default PrivateRoute;
