import Cookies from 'js-cookie';
import React, { useState, useMemo, useReducer, useEffect } from 'react';
import { UserContext, UserDispatchContext } from '../unityauth/internal';
import { createUser } from '../unityauth/user';
import axios from 'axios';

const emptyUser = {
  name: '',
  firstName: '',
  familyName: '',
  jobTitle: '',
  email: '',
  mobile: '',
  phone: '',
  id: '',
  location: '',
  aadId: '',
  enId: '',
  accessToken: '',
  defaultTenantId: '',
  currentTenantId: '',
  tenantIds: [],
  roleIds: [],
  products: [],
  permissions: [],
  can: {},
  statusCode: undefined,
};

export const EnAuthProvider = props => {
  const { children } = props;
  const [enId, setEnId] = useState();
  const [accessToken, setAccessToken] = useState(Cookies.get('token'));
  const [didLoad, setDidLoad] = useState(false);

  const user = useMemo(() => {
    if (enId && accessToken) {
      return createUser({
        enId: enId,
        accessToken: accessToken,
      });
    }

    return null;
  }, [enId, accessToken]);

  const [userState, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'SET_USER':
          state = {
            ...state,
            user: action.user,
          };
          break;
        default:
          throw new Error(`Unknown action type ${action.type}`);
      }

      return state.user;
    },
    user ? user : emptyUser
  );

  useEffect(() => {
    const getUserInfo = async () => {
      const enResponse = await axios
        .request({
          method: 'get',
          url: `${process.env.REACT_APP_EN_API_BASE}/user`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .catch(e => {
          console.log(e);
        });
      setEnId(enResponse?.data?.user?.userId);
    };
    if (accessToken) {
      getUserInfo();
    }
  }, [accessToken]);

  useEffect(() => {
    if (!user?.enId || !user?.accessToken || didLoad) {
      return;
    }
    setDidLoad(true);
    dispatch({ type: 'SET_USER', user });
  }, [user, dispatch, didLoad]);

  return (
    <UserContext.Provider value={userState}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserContext.Provider>
  );
};
