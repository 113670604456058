export const getCompanyPropertyVal = (companies, companyId, key) => {
  if (companies) {
    if (companies.length > 0) {
      let company = companies?.find(c => c?.companyId === companyId);
      return company ? company[key] : '';
    }
  }
  return '';
};

export const getCompany = (companies, loggedUser) => {
  let companyDataWithoutGlobal = [];
  if (companies) {
    companyDataWithoutGlobal = companies.company.filter(c => c.companyId !== 0);
  }
  if (companies && loggedUser) {
    //TODO fix me: was causing a bug where it would return {id: '', name: ''}
    if (
      sessionStorage.getItem('company') &&
      Object.keys(JSON.parse(sessionStorage.getItem('company'))).length > 0 &&
      JSON.parse(sessionStorage.getItem('company')).id !== ''
    ) {
      return JSON.parse(sessionStorage.getItem('company'));
    }

    if (loggedUser) {
      const companyData = {
        id: getCompanyPropertyVal(
          companyDataWithoutGlobal,
          loggedUser.currentProfileCompanyId,
          'companyId'
        ),
        name: getCompanyPropertyVal(
          companyDataWithoutGlobal,
          loggedUser.currentProfileCompanyId,
          'companyName'
        ),
      };
      sessionStorage.setItem('company', JSON.stringify(companyData));
      return companyData;
    }
  }
  return { id: 0, name: '' };
};
