import React, {
  lazy,
  Suspense,
  useState,
  useMemo,
  useEffect,
  useCallback,
} from 'react';
import {
  Switch,
  Route,
  Redirect,
  BrowserRouter as Router,
} from 'react-router-dom';
import { useMediaQuery } from '@material-ui/core';
import PrivateRoute from './utils/employee-network-api-connector/PrivateRoute';
import { SnackbarProvider } from 'notistack';
import PageLoading from './UI/routing/PageLoading';
import ErrorBoundary from './ErrorBoundary';
import ShellWrapper from './ShellWrapper';
import { ShellContext } from './contexts/ShellContext';
import AppBarControls from './appBarContent/AppBarControls';
import UserWrapper from './appBarContent/UserWrapper';
import { useAxiosQuery } from './utils/useAxios';
import { useUserActions } from './utils/unityauth/users';
import { mockMenus } from './mockData/mockData';

import AadCallback from './routes/callback/AadCallback';
import UnauthenticatedModal from './utils/unityauth/UnauthenticatedModal';
import { useUser, useIsUnityAuthenticated } from './utils/unityauth';
import { getUserFullName, userHasPermission } from './utils/userUtils';

const MembershipCard = lazy(() =>
  import('./components/ui/profile/MembershipCard')
);
const PageNotFound = lazy(() => import('./UI/routing/PageNotFoundRoute'));
const Login = lazy(() => import('./routes/shared/Login'));
const ErrorBoundaryDemo = lazy(() =>
  import('./routes/errorBoundary/ErrorBoundaryDemo')
);
const LandingPage = lazy(() => import('./routes/LandingPage'));
const SurveyPage = lazy(() => import('./routes/SurveyPage'));
const ProfileSummary = lazy(() => import('./routes/ProfileSummary'));
const Companies = lazy(() => import('./routes/Companies'));
const Affiliates = lazy(() => import('./routes/Affiliates'));
const JobPostings = lazy(() => import('./routes/JobPostings'));
const Employees = lazy(() => import('./routes/Employees'));
const CertificateTypes = lazy(() => import('./routes/CertificateTypes'));
const Projects = lazy(() => import('./routes/Projects'));
const ProjectAssignments = lazy(() => import('./routes/ProjectAssignments'));
const ProjectTimesheets = lazy(() => import('./routes/ProjectTimesheets'));
const ProjectSummary = lazy(() => import('./routes/ProjectSummary'));
const Dashboard = lazy(() => import('./routes/Dashboard'));
const EmploymentNetwork = lazy(() => import('./routes/EmploymentNetwork'));
const Incidents = lazy(() => import('./routes/Incidents'));
const JoshCommitteeTracking = lazy(() =>
  import('./routes/JoshCommitteeTracking')
);
const EmployeesDetails = lazy(() => import('./routes/EmployeeDetails'));
const SDSSheets = lazy(() => import('./routes/SDSSheets'));
const ToolboxTalks = lazy(() => import('./routes/ToolboxTalks'));
const AdminSettings = lazy(() => import('./routes/AdminSettings'));
const Reports = lazy(() => import('./routes/Reports'));

const RegisterPage = lazy(() => import('./routes/RegisterPage'));
const EmailVerification = lazy(() => import('./routes/EmailVerification'));

const IS_LOCAL_AUTH = process.env.REACT_APP_LOCAL_AUTH === 'true';

const Routes = () => {
  const userContext = useUser();
  const { setUser } = useUserActions();
  const [menuToggle, setMenuToggle] = useState(true);
  const [inquiryMenuToggle, setInquiryMenuToggle] = useState(true);
  const [headerExpander, setHeaderExpander] = useState(false);
  const [headerTitle, setHeaderTitle] = useState('');
  const [secondExtendedWindow, setSecondExtendedWindow] = useState(false);
  const [showToggle1, setShowToggle1] = useState(false);
  const [showToggle2, setShowToggle2] = useState(false);
  const [primaryExpanded, setPrimaryExpanded] = useState(false);
  const [secondaryExpanded, setSecondaryExpanded] = useState(true);
  const isAuthenticated = useIsUnityAuthenticated();
  const [menus, setMenus] = useState([]);
  const [selectedSwitchItem, SetSelectedSwitchItem] = useState(
    sessionStorage.getItem('company')
      ? JSON.parse(sessionStorage.getItem('company')).name
      : 'Select Company'
  );
  const mobileSize = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const switcherWidth = mobileSize ? 80 : 150;

  const useMockData = process.env.REACT_APP_USE_MOCKS === 'true';

  const [{ data: leftMenu }] = useAxiosQuery(
    {
      url: `${process.env.REACT_APP_EN_API_BASE}/system/unityMenus`,
      method: 'get',
    },
    false,
    false
  );
  const [{ data: loggedUserData }, refetchUser] = useAxiosQuery(
    {
      url: `users/${userContext?.enId}`,
      method: 'get',
    },
    !userContext?.enId
  );
  const [{ data: companiesData }, refetchCompanies] = useAxiosQuery({
    url: `company?organizationType=company`,
    method: 'get',
  });
  const switchCompany = item => {
    //unity project switcher has setting that it always send label on click
    if (item && companies) {
      const selectedCompany = companies.find(c => c.label === item);
      sessionStorage.setItem(
        'company',
        JSON.stringify({
          id: selectedCompany.value.companyId,
          name: selectedCompany.value.companyName,
        })
      );
    }

    SetSelectedSwitchItem(item);
    window.location.reload();
  };
  const companies = useMemo(() => {
    if (!companiesData) {
      return [];
    }
    const activeCompanies = companiesData.company
      .filter(c => c.companyStatus === 'active' && c.companyId !== 0)
      .sort((a, b) => a.companyName.localeCompare(b.companyName));
    return activeCompanies.map(ac => {
      const updateCompanyObj = {
        label: ac.companyName,
        click: ac => switchCompany(ac),
        key: ac.companyId,
        value: ac,
      };
      return updateCompanyObj;
    });
  }, [companiesData]);

  const loggedUser = useMemo(() => {
    if (!loggedUserData) {
      return null;
    }
    const userObj = loggedUserData.user;
    return {
      userId: userObj.userId,
      userType: userObj.userType,
      userCondition: userObj.userCondition,
      current_profileId: userObj.current_profileId,
      currentProfileCompanyId: userObj.currentProfile.companyId,
      name: getUserFullName(
        userObj.currentProfile.firstName,
        userObj.currentProfile.middleName,
        userObj.currentProfile.lastName
      ),
      email: userObj.emailAddress_primary,
      permissions: userObj.permissions,
      adminCompanies: userObj.adminCompanies,
    };
  }, [loggedUserData]);

  const updateMenuListByPermission = useCallback(
    permissions => {
      let jsonObj = JSON.parse(leftMenu.unityMenus)[0];
      let menuItems = [...jsonObj.menuItemInstanceStructureViews];

      // convert the structure of the menu items to match EN structure:
      // each menu item must have item.labelText (equivalent to item.title from Unity)
      // item.children = item.menuItemInstanceStructures
      menuItems.forEach(mi => {
        mi.labelText = mi.title;

        if (mi.hasOwnProperty('menuItemInstanceStructures')) {
          mi.children = mi.menuItemInstanceStructures;

          mi.children.forEach(mic => (mic.labelText = mic.title));
        }
      });

      if (permissions) {
        let safetyMenu = menuItems.find(item => item.labelText === 'Safety');
        let safetyMenuChildren = safetyMenu?.children;

        let projectsMenu = menuItems.find(
          item => item.labelText === 'Projects'
        );
        let projectsMenuChildren = projectsMenu?.children;

        // only admins and companyAdmins should see EN
        if (
          loggedUser?.userType !== 'admin' &&
          loggedUser?.userType !== 'companyAdmin'
        ) {
          menuItems = menuItems.filter(
            item => item.labelText !== 'Employment Network'
          );
          // users should not see dashboard
          if (loggedUser?.userType === 'user') {
            menuItems = menuItems.filter(
              item => item.labelText !== 'Dashboard'
            );
          }
        }

        if (!userHasPermission(permissions, 'projects_view')) {
          projectsMenuChildren = projectsMenuChildren?.filter(
            item => item.labelText !== 'Ongoing Projects'
          );
        }
        if (!userHasPermission(permissions, 'projectWorkers_view')) {
          projectsMenuChildren = projectsMenuChildren?.filter(
            item => item.labelText !== 'Assignments'
          );
          projectsMenuChildren = projectsMenuChildren?.filter(
            item => item.labelText !== 'Summary'
          );
        }
        if (!userHasPermission(permissions, 'projectTimesheets_view')) {
          projectsMenuChildren = projectsMenuChildren?.filter(
            item => item.labelText !== 'Timesheets'
          );
        }
        if (!userHasPermission(permissions, 'employees_view')) {
          menuItems = menuItems.filter(item => item.labelText !== 'Employees');
        }
        if (!userHasPermission(permissions, 'companies_edit')) {
          menuItems = menuItems.filter(item => item.labelText !== 'Companies');
        }
        if (!userHasPermission(permissions, 'affiliates_view')) {
          menuItems = menuItems.filter(item => item.labelText !== 'Affiliates');
        }
        if (loggedUser && loggedUser.userType === 'user') {
          menuItems = menuItems.filter(
            item => item.labelText !== 'Certificate Types'
          );
        }
        if (
          loggedUser &&
          loggedUser.userType === 'user' &&
          (loggedUser.userCondition === 'working' ||
            loggedUser.currentProfileCompanyId !== 0)
        ) {
          menuItems = menuItems.filter(
            item => item.labelText !== 'Job Postings'
          );
        }
        if (!userHasPermission(permissions, 'reports_view')) {
          menuItems = menuItems.filter(item => item.labelText !== 'Reports');
        }
        if (!userHasPermission(permissions, 'incidents_view')) {
          safetyMenuChildren = safetyMenuChildren?.filter(
            item => item.labelText !== 'Incidents'
          );
        }
        if (!userHasPermission(permissions, 'josh_view')) {
          safetyMenuChildren = safetyMenuChildren?.filter(
            item => item.labelText !== 'JOSH Committee Tracking'
          );
        }
        if (!userHasPermission(permissions, 'sdsSheets_view')) {
          safetyMenuChildren = safetyMenuChildren?.filter(
            item => item.labelText !== 'SDS Sheets'
          );
        }
        if (!userHasPermission(permissions, 'toolboxtalks_view')) {
          safetyMenuChildren = safetyMenuChildren?.filter(
            item => item.labelText !== 'Toolbox Talks'
          );
        }
        // update safety folder menu items
        const safetyIndex = menuItems.findIndex(
          item => item.labelText === 'Safety'
        );
        if (safetyMenu?.children) {
          safetyMenu.children = safetyMenuChildren;
          menuItems[safetyIndex] = safetyMenu;
        }

        //remove Projects parent tab if user has no options
        if (
          !userHasPermission(permissions, 'projects_edit') &&
          !userHasPermission(permissions, 'projectWorkers_view') &&
          !userHasPermission(permissions, 'projectTimesheets_view')
        ) {
          menuItems = menuItems.filter(item => item.labelText !== 'Projects');
        }
        // update projects folder menu items
        const projectsIndex = menuItems.findIndex(
          item => item.labelText === 'Projects'
        );
        if (projectsMenu?.children) {
          projectsMenu.children = projectsMenuChildren;
          menuItems[projectsIndex] = projectsMenu;
        }
        if (
          !userHasPermission(permissions, 'incidents_view') &&
          !userHasPermission(permissions, 'josh_view') &&
          !userHasPermission(permissions, 'sdsSheets_view') &&
          !userHasPermission(permissions, 'toolboxtalks_view')
        ) {
          menuItems = menuItems.filter(item => item.labelText !== 'Safety');
        }
      } else {
        menuItems = [];
      }

      // convert back to unity format to make it compatible with Unity ShellWrapper
      setMenus([
        {
          ...jsonObj, // retain properties from the top-level parent object from the unity response
          menuItemInstanceStructureViews: menuItems,
        },
      ]);
    },
    [leftMenu, loggedUser]
  ); // do not put loggedUser here

  // const [{ data: appSelectorMenus }] = useAxiosQuery(
  //   {
  //     url: `${process.env.REACT_APP_TENANTS_API_BASE}/menus?tenantId=${
  //       userContext?.currentTenantId
  //     }&verticalId=${process.env.REACT_APP_UNITY_VERTICAL_ID}&userId=${
  //       userContext ? userContext?.id : ''
  //     }`,
  //     method: 'get',
  //   },
  //   false,
  //   false
  // );

  useEffect(() => {
    if (leftMenu && loggedUser) {
      updateMenuListByPermission(loggedUser.permissions);
    }
  }, [leftMenu, loggedUser, updateMenuListByPermission]);

  if (userContext?.statusCode === 401) {
    return <UnauthenticatedModal />;
  }

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <SnackbarProvider>
        <ShellContext.Provider
          value={{
            menuToggle,
            setMenuToggle,
            headerExpander,
            setHeaderExpander,
            headerTitle,
            setHeaderTitle,
            secondExtendedWindow,
            setSecondExtendedWindow,
            showToggle1,
            setShowToggle1,
            showToggle2,
            setShowToggle2,
            primaryExpanded,
            setPrimaryExpanded,
            secondaryExpanded,
            setSecondaryExpanded,
            inquiryMenuToggle,
            setInquiryMenuToggle,
          }}
        >
          <ShellWrapper
            userAvatar={<UserWrapper userContext={userContext} />}
            appContent={<AppBarControls />}
            leftMenu={menus && loggedUser ? menus : []}
            user={userContext}
            navRoot={'Home'}
            siteName="Employee Network"
            menuToggle={menuToggle}
            inquiryMenuToggle={inquiryMenuToggle}
            setInquiryMenuToggle={setInquiryMenuToggle}
            headerExpander={headerExpander}
            secondExtendedWindow={secondExtendedWindow}
            showToggle1={showToggle1}
            showToggle2={showToggle2}
            primaryExpanded={primaryExpanded}
            secondaryExpanded={secondaryExpanded}
            setPrimaryExpanded={setPrimaryExpanded}
            setSecondaryExpanded={setSecondaryExpanded}
            useMockData={useMockData}
            mockMenus={mockMenus}
            unityUrl={process.env.REACT_APP_UNITY_URL}
            hideLayout={!isAuthenticated && IS_LOCAL_AUTH}
            switcherData={
              companies &&
              loggedUser &&
              loggedUser.currentProfileCompanyId !== 0
                ? companies
                : ''
            }
            selectedItem={
              selectedSwitchItem &&
              loggedUser &&
              loggedUser.currentProfileCompanyId !== 0
                ? selectedSwitchItem
                : false
            }
            switcherWidth={
              switcherWidth &&
              loggedUser &&
              loggedUser.currentProfileCompanyId !== 0
                ? switcherWidth
                : false
            }
          >
            <ErrorBoundary>
              <Suspense fallback={<PageLoading />}>
                <Route path="/home" exact component={LandingPage} />
                <Route path="/register" exact component={RegisterPage} />
                <Route
                  path="/verify-email"
                  exact
                  component={EmailVerification}
                />
                <Route path="/memberCard" component={MembershipCard} />
                <Route path="/survey" exact component={SurveyPage} />
                <Route exact path="/aad_callback" component={AadCallback} />

                {process.env.REACT_APP_EN_UNITY_SSO === 'true_xx' &&
                !userContext?.accessToken ? null : (
                  <Switch>
                    <Route path="/login" exact component={Login} />

                    <PrivateRoute
                      path="/profile/summary"
                      exact
                      component={ProfileSummary}
                    />
                    <PrivateRoute
                      path="/dashboard"
                      exact
                      component={Dashboard}
                    />
                    {/* <Redirect exact from="/" to="/dashboard" /> */}
                    <Redirect exact from="/" to="/profile/summary" />
                    <PrivateRoute
                      path="/job-postings"
                      exact
                      component={JobPostings}
                    />
                    <PrivateRoute
                      path="/employment-network"
                      exact
                      component={EmploymentNetwork}
                    />
                    <PrivateRoute
                      path="/affiliates"
                      exact
                      component={Affiliates}
                    />
                    <PrivateRoute
                      path="/companies"
                      exact
                      component={Companies}
                    />
                    <PrivateRoute
                      path="/employees/Employees"
                      exact
                      component={Employees}
                    />
                    <PrivateRoute
                      path="/employees/Employee-Details"
                      exact
                      component={EmployeesDetails}
                    />
                    <PrivateRoute
                      path="/projects/ongoing-projects"
                      exact
                      component={Projects}
                    />
                    <PrivateRoute
                      path="/projects/assignments"
                      exact
                      component={ProjectAssignments}
                    />
                    <PrivateRoute
                      path="/projects/timesheets"
                      exact
                      component={ProjectTimesheets}
                    />
                    <PrivateRoute
                      path="/projects/summary"
                      exact
                      component={ProjectSummary}
                    />
                    <PrivateRoute
                      path="/certificate-types"
                      exact
                      component={CertificateTypes}
                    />
                    <PrivateRoute
                      path="/safety/incidents"
                      exact
                      component={Incidents}
                    />
                    <PrivateRoute
                      path="/safety/josh-committee-tracking"
                      exact
                      component={JoshCommitteeTracking}
                    />
                    <PrivateRoute
                      path="/safety/sds-sheets"
                      exact
                      component={SDSSheets}
                    />
                    <PrivateRoute
                      path="/safety/toolbox-talks"
                      exact
                      component={ToolboxTalks}
                    />
                    <PrivateRoute
                      path="/admin-settings"
                      exact
                      component={AdminSettings}
                    />

                    <PrivateRoute path="/reports" exact component={Reports} />
                    <ErrorBoundary message="This is a demo error message">
                      <Route
                        path="/error"
                        exact
                        component={ErrorBoundaryDemo}
                      />
                    </ErrorBoundary>
                    <Route path="*" component={PageNotFound} />
                  </Switch>
                )}
              </Suspense>
            </ErrorBoundary>
          </ShellWrapper>
        </ShellContext.Provider>
      </SnackbarProvider>
    </Router>
  );
};
export default Routes;
