import { createContext } from 'react';
import { Ability } from '@casl/ability';
import { createContextualCan } from '@casl/react';
import rules from './rules.json';

const AbilityContext = createContext();
const Can = createContextualCan(AbilityContext.Consumer);

const defineAbility = () => new Ability([...rules]);

export { AbilityContext, Can, defineAbility };
