import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
  getStoredPath,
  clearStoredPath,
} from '../../utils/redirect/LocalRedirectUrlStorage';
import {
  getStoredExternalPath,
  clearStoredExternalPath,
} from '../../utils/redirect/ExternalRedirectUrlStorage';
import { CircularProgress } from '@material-ui/core';
import { useUser } from '../../utils/unityauth';
import PageLoading from '../../UI/routing/PageLoading';

const AadCallback = props => {
  const localUrl = getStoredPath();
  const externalUrl = getStoredExternalPath();
  const userContext = useUser();

  clearStoredPath();
  clearStoredExternalPath();

  if (externalUrl) {
    return (
      <Route
        component={() => {
          window.location.href = externalUrl;
          return null;
        }}
      />
    );
  }
  if (userContext?.enId) {
    return <Redirect to={localUrl === '/aad_callback' ? '/' : localUrl} />;
  }
  return <PageLoading />;
};

export default AadCallback;
