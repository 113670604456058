import { useContext, useCallback } from 'react';
import { UserDispatchContext } from './internal';

export function useUserActions() {
  const dispatch = useContext(UserDispatchContext);
  const setUser = useCallback(
    user => {
      dispatch({ type: 'SET_USER', user });
    },
    [dispatch]
  );

  return { setUser };
}
