import React, { Suspense, useState, useEffect } from 'react';
import Routes from './Routes';
import { ThemeProvider } from './utils/theme';
import { CssBaseline } from '@material-ui/core';
import { PermissionContext } from './contexts/PermissionContext';
import { EnAuthProvider } from './utils/employee-network-api-connector/EnAuthProvider';
import { GqlApiProvider } from './api/client';
import { AbilityContext, defineAbility } from './permissions/ability';

import { AuthProvider } from './utils/unityauth/AuthProvider';
import { CompanyRequestProvider } from './contexts/CompanyRequestContext';
import { storeInitialPath } from './utils/redirect/LocalRedirectUrlStorage';

let App = () => {
  const ability = defineAbility();
  const [isAuth, setIsAuth] = useState(null);
  const [permissionOptions, setPermissionOptions] = useState({
    level: 'silver',
    option: true,
    mapOption: true,
    mapEditOption: true,
    mapViewOption: true,
  });
  storeInitialPath();
  const changePermission = permission => {
    setPermissionOptions(permission);
  };

  useEffect(() => {
    setIsAuth(true);
  }, []);

  return (
    // <BrandingContext branding={whitelabelBranding} />,
    <Suspense fallback="loading">
      <ThemeProvider>
        {process.env.REACT_APP_EN_UNITY_SSO === 'true' ? (
          <AuthProvider>
            <AbilityContext.Provider value={ability}>
              <PermissionContext.Provider value={permissionOptions}>
                <GqlApiProvider>
                  <CssBaseline />
                  <CompanyRequestProvider>
                    <Routes />
                  </CompanyRequestProvider>
                </GqlApiProvider>
              </PermissionContext.Provider>
            </AbilityContext.Provider>
          </AuthProvider>
        ) : (
          <EnAuthProvider>
            <AbilityContext.Provider value={ability}>
              <PermissionContext.Provider value={permissionOptions}>
                <GqlApiProvider>
                  <CssBaseline />
                  <CompanyRequestProvider>
                    <Routes />
                  </CompanyRequestProvider>
                </GqlApiProvider>
              </PermissionContext.Provider>
            </AbilityContext.Provider>
          </EnAuthProvider>
        )}
      </ThemeProvider>
    </Suspense>
  );
};

export default App;
