import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from '@material-ui/core';

const ModalAlert = props => {
  const [check, setCheck] = useState();
  const {
    open,
    action,
    title,
    message,
    openAlert,
    closeAlert,
    confirm,
  } = props;

  const confirmAction = event => {
    setCheck(event.target.value);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={closeAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
          {confirm && (
            <>
              <Typography>
                Type DELETE in order to complete this task
              </Typography>
              <TextField
                label="Confirm Action"
                id="confirm"
                variant="outlined"
                fullWidth
                size="small"
                onChange={confirmAction}
                style={{ marginTop: 15 }}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          {confirm ? (
            <>
              <Button onClick={closeAlert} color="primary">
                Disagree
              </Button>
              <Button
                onClick={action}
                onMouseUp={closeAlert}
                color="primary"
                autoFocus
                disabled={confirm && check?.toUpperCase() !== 'DELETE'}
              >
                Agree
              </Button>
            </>
          ) : (
            <Button onClick={closeAlert} color="primary">
              Close
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default ModalAlert;
