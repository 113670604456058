export const userConditions = [
  //updated as of 07-27-2021
  { label: 'Applicant', value: 'applicant' },
  { label: 'Working', value: 'working' },
  { label: 'Not Working', value: 'nonWorking' },
  { label: 'Lapsed', value: 'lapsed' },
  { label: 'On Leave', value: 'onLeave' },
];

export const lookingWorkStatus = [
  { label: 'Yes', value: 'looking' },
  { label: 'No', value: 'notLooking' },
];

export const userHasPermission = (permissions, permission) => {
  if (permissions && permissions[permission] === 'yes') {
    return true;
  }
  return false;
};

export const getUserFullName = (firstName, middleName, lastName) => {
  let name = '';
  if (firstName && firstName.length > 0) {
    name += firstName + ' ';
  }

  if (middleName && middleName.length > 0) {
    name += middleName + ' ';
  }

  if (lastName && lastName.length > 0) {
    name += lastName;
  }

  return name;
};

/**
 * takes an array of user profiles and returns an array of
 * user profiles that only contain the latest profile of each user
 */
export const sortToLatestUserProfile = profiles => {
  let userProfilesArray = [];
  // filter userList to show latest profile for each user
  profiles.userProfile.forEach(user => {
    if (!userProfilesArray.find(u => u.userId === user.userId)) {
      // get array of userProfile of userId, sort by latest to oldest
      const userProfileList = profiles.userProfile
        .filter(up => up.userId === user.userId)
        .sort((a, b) => (a.profileId < b.profileId ? 1 : -1));

      let latestProfile = userProfileList[0];
      latestProfile.name = getUserFullName(
        latestProfile.firstName,
        latestProfile.middleName,
        latestProfile.lastName
      );
      latestProfile.id = latestProfile.profileId;
      userProfilesArray.push(latestProfile);
    }
  });

  return userProfilesArray.sort((a, b) =>
    a.firstName.localeCompare(b.firstName)
  );
};
