import React from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  makeStyles,
  Typography,
  lighten,
} from '@material-ui/core';
import { FluentButton } from 'unity-fluent-library';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 550,
    marginRight: theme.spacing(2),
    background: lighten(theme.palette.info.light, 0.8),
    borderTop: `4px solid ${theme.palette.secondary.main}`,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(2), // Added padding due to outlined button change from standard button
  },
  header: {
    color: theme.palette.secondary.main,
  },
}));

const FluentSystemCard = props => {
  const classes = useStyles();
  const { header, subheader, buttonlabel, body } = props;

  return (
    <Card className={classes.root} elevation={0}>
      <CardContent className={classes.content}>
        <Typography
          gutterBottom
          variant="h5"
          component="h2"
          className={classes.header}
        >
          {header}
        </Typography>
        <Typography gutterBottom variant="caption" component="h3">
          {subheader}
        </Typography>
        {body}
      </CardContent>
      {buttonlabel && (
        <CardActions className={classes.actions}>
          <FluentButton size="small" color="secondary" variant="contained">
            {buttonlabel}
          </FluentButton>
        </CardActions>
      )}
    </Card>
  );
};

export default FluentSystemCard;
