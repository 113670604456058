import { useContext } from 'react';
import { UserContext } from './internal';
import { User } from './user';
import Cookies from 'js-cookie';

/**
 * Get the current user object (or null if not logged in)
 *
 * Does not redirect to login
 * @see {@link useAuthedUser}
 */
export function useUser(): User | null {
  
  let userContext = useContext(UserContext);

  let actorCookie = Cookies.get('actor');
  if(actorCookie !== undefined && userContext != null){
    userContext['actor_enId'] = actorCookie;
    userContext['enId']=actorCookie;
  }

  return userContext;
}
