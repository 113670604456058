import React from 'react';
import { storeInitialPath } from '../redirect/LocalRedirectUrlStorage';
import { useUser, useLogoutAction } from './';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

const UnauthenticatedModal = () => {
  const userContext = useUser();
  const logout = useLogoutAction();
  storeInitialPath();

  if (userContext?.statusCode === 401) {
    return (
      <div>
        <Dialog
          open={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {'There was a problem authenticating'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {
                'Please logout and try again. If the problem persists, please contact support.'
              }
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => logout()} color="primary">
              Logout
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
};
export default UnauthenticatedModal;
